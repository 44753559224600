.registerPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
    border-radius: 10px;
    box-shadow: 0 0 7px 0 #ccc;
}

.stockTitle {
    color: #2685c4;
}

@media (max-width: 667px) {
  .registerPage {
    width: inherit;
    padding: 1em;
    border-radius: 0;
    box-shadow: 0;
  }
}
