.stockPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 2em;
    min-height: 40em;
    border-radius: 10px;
    box-shadow: 0 0 7px 0 #ccc;
}

.stockFormHolder {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 1.5em;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 7px 0 #ccc;
    margin-top: 1em;
    /* min-height: 40em; */
}

.stockTitle {
    color: #2685c4;
}

.stockMessage {
    width: 50%;
    margin: 1.5em auto;
    background-color: white;
    padding: 0.2em 2em;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 7px 0 #ccc;
}

.stockForm {
    width: 50%;
    background-color: white;
    padding: 1.5em;
    /* border-radius: 10px;
    box-shadow: 0 0 7px 0 #ccc; */
}

.rickshaw_graph {
    border: 1px solid #ccc;
}

.stockPage h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.stockPrice {
    border: 1px solid #ccc;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .stockPage {
        width: inherit;
        padding: 1em;
        margin: 0;
        border-radius: 0;
    }

    .stockFormHolder {
        flex-direction: column;
        width: inherit;
        border-radius: 0;
    }

    .stockForm {
        width: inherit;
        background-color: white;
        padding: 1.5em;
        margin: 1em;
    }

    .stockMessage {
        width: inherit;
        padding: 1em;
        border-radius: 0
    }
}
