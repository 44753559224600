.button {
  color: #333;
  background-color: #fff;
  border: 1px solid #cccccc;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Open sans", sans-serif;
  font-size: 1.4rem;
  padding: 0.4rem 1rem;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 1.4rem;
  width: 100%;
}

.createButton {
  color: #333;
  background-color: #fff;
  background-image: linear-gradient(#fff, #cccccc);
  border: 1px solid #cccccc;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Open sans", sans-serif;
  font-size: 1rem;
  padding: 0.2rem 0.2rem;
  text-align: center;
  border-radius: 0.2rem;
  line-height: 1.4;
  margin-bottom: 1.4rem;
  box-shadow: 0px 3px 5px 0px #999;
}

.editButton {
    width: 50%;
    margin: 1em auto;
}

.button:hover {
  background-color: #b3b3b3;
  background-image: none;
}

.button:active {
  background-color: #a6a6a6;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.full-width-button {
width: 100%;
}

.blue-button {
  color: #fff;
  background-color: #1e6a9c;
  margin-top: 0.5em;
}

.blue-button:hover {
  background-color: #164f75;
  background-image: none;
}

.blue-button:active {
  background-color: #001527;
  background-image: none;
}

.green-button {
  color: #fff;
  background-color: #00a282;
}

.green-button:hover {
  background-color: #007961;
  background-image: none;
}

.green-button:active {
  background-color: #003c30;
  background-image: none;
}
