.userPage {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
    border-radius: 10px;
    box-shadow: 0 0 7px 0 #ccc;
}

th {
    text-align: left;
}


th,
td {
    white-space: nowrap;
    padding: 0.8rem 1.6rem;
}

.right {
    text-align: right;
}


tr:nth-of-type(2n) {
    background-color: #ddd;
}

.noStock {
    text-align: center;
}

.stockTitle {
    color: #2685c4;
}

.table {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border-collapse: collapse;
    margin-bottom: 1.4rem;
    box-shadow: 0 3px 5px 0 #999;
    border-radius: 0.5rem;
}

@media screen and (max-width: 700px) {
    .userPage {
        width: inherit;
        padding: 1em;
        margin: 0;
        border-radius: 0;
        background-color: #fbfbfb;
    }
    .table {
        width: 100%;
        box-shadow: 0 0 0 0;
        border: 1px solid #ccc;
        border-radius: 0;
    }
}
