html {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    color: #555;
    font-size: 100%;
    background-color: #2685c4;
}

.App-body {
    background-color: #fbfbfb;
    margin: 0 auto;
    min-height: 40em;
    line-height: 1.4;
    padding-top: 1em;
    padding-bottom: 1em;
}

/* .app {
    position: relative;
} */

main {
    padding: 1.4rem 0.6rem;
}

h1,
h2,
h3,
h4 {
    margin-top: 0;
}

h1 {
    font-size: 2.4rem;
    margin-bottom: 3.36rem;
}

h2 {
    font-size: 2rem;
    margin-bottom: 1.68rem;
}

h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
}

code {
    padding: 0.2em;
    border-radius: 5px;
    background-color: #eee;
}

.title {
    font-size: 2.4rem;
    margin-bottom: 2.8rem;
    border-bottom: 1px solid #ccc;
}

.pageFooter {
    /* position: relative; */
    /* bottom: 0; */
    margin-top: 1em;
    width: 100%;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    background-color: #2685c4;
    color: #fff;
    min-height: 10em;
}

.pageFooter p {
    padding-top: 1.5em;
}

/* @media screen and (max-width: 700px) {
    .NavBar a {
        font-size: 1rem;
    }
    .navBtn p {
        display: none;
    }
} */




/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
